import { lazy } from 'react'
import * as Constants from './constants'
import util from 'util'

import HomeIcon from '@material-ui/icons/Home'
import MapIcon from '@material-ui/icons/Map'
import BusinessIcon from '@material-ui/icons/Business'
import BarChartIcon from '@material-ui/icons/BarChart'
import PersonIcon from '@material-ui/icons/Person'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import AssessmentIcon from '@material-ui/icons/Assessment'
import EventIcon from '@material-ui/icons/Event'
import SensyIcon from 'components/sensy-logo/'

/**
 * Load login page asynchronously. We only need this if we need
 * to redirect. We also need to import the "connected" component
 * and not the raw component.
 */
const Login = lazy(() => {
  return import('components/login/')
    .then(module => ({ default: module.Connected }))
})

const WORKSPACE = 'workspace'
const REPORTING_DASH = 'reporting-dashboards'
const REPORTING = 'reporting'
const ACCOUNT = 'account'
const DEVICES = 'devices'
const SENSY = 'sensy'

/*
 | Routes
 | ------
 | List of application routes.
 */
const orderedRoutes = [
  {
    path: Constants.PATH.LOGIN,
    component: Login
  },
  // Home/Dashboard
  // --------------
  {
    path: Constants.PATH.HOME,
    component: lazy(() => import('components/page/dashboard/')),
    label: (
      <>
        Home <span style={{fontSize: '0.7em', color: '#fefefe', marginLeft: '5px', position: 'relative', top:'-0.7px'}}>v1.0.6</span>
      </>
    ),
    icon: HomeIcon
  },
  // Floor viewer
  // -------
  {
    path: Constants.PATH.WORKSPACE_VIEWER,
    component: lazy(() => import('components/page/workspace/viewer/')),
    label: 'Floor Viewer',
    icon: MapIcon
  },
  // ------------------------------------------------- //

  // Reporting
  // ---------
  {
    id: REPORTING_DASH,
    icon: AssessmentIcon,
    label: 'Reports'
  },
  {
    id: REPORTING,
    icon: BarChartIcon,
    label: 'Analytics'
  },
  {
    path: Constants.PATH.REPORTING_ANALYTICS,
    component: lazy(() => import('components/page/reporting/analytics/')),
    label: 'Analyze',
    parent: REPORTING
  },
  {
    path: Constants.PATH.REPORTING_AREA_BREAKDOWN,
    component: lazy(() => import('components/page/reporting/area-breakdown/')),
    label: 'Area Breakdown',
    parent: REPORTING
  },

  // Reports
  {
    path: Constants.PATH.REPORTING_REPORT,
    component: lazy(() => import('page/reports/report/Report')),
    label: 'EAI (Beta)',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_UTILIZATION_SPACES,
    component: lazy(() => import('components/page/reporting/utilization/spaces/')),
    label: 'Spaces',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_UTILIZATION_ROOMS,
    component: lazy(() => import('components/page/reporting/utilization/rooms/')),
    label: 'Rooms',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_UTILIZATION_TRAFFIC,
    component: lazy(() => import('page/reports/traffic/Traffic')),
    label: 'Traffic',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_HUMAN_EXPERIENCE_COMFORT,
    component: lazy(() => import('components/page/reporting/human-experience/comfort/')),
    label: 'Comfort',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_HUMAN_EXPERIENCE_PRODUCTIVITY,
    component: lazy(() => import('components/page/reporting/human-experience/productivity/')),
    label: 'Productivity',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_AIR_QUALITY_HEALTH,
    component: lazy(() => import('components/page/reporting/air-quality/health/')),
    label: 'Health',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_AIR_QUALITY_INDEX,
    component: lazy(() => import('page/reports/aqi/Aqi')),
    admin: true,
    label: 'Air Quality Index',
    parent: REPORTING_DASH
  },
  {
    path: Constants.PATH.REPORTING_STD_BANK_UTILIZATION,
    component: lazy(() => import('components/page/reporting/dashboards/std-bank-utilization'))
  },
  {
    path: Constants.PATH.REPORTING_TECHDATA_OCC,
    component: lazy(() => import('components/page/reporting/dashboards/tech-data'))
  },
  {
    path: Constants.PATH.REPORTING_TECHDATA_AIR,
    component: lazy(() => import('components/page/reporting/dashboards/tech-data-air'))
  },
  {
    path: Constants.PATH.REPORTING_AMSTERDAM,
    component: lazy(() => import('components/page/reporting/dashboards/amsterdam-demo'))
  },
  {
    path: Constants.PATH.REPORTING_GYM,
    component: lazy(() => import('components/page/reporting/dashboards/gym'))
  },
  {
    path: Constants.PATH.REPORTING_DIDATA,
    component: lazy(() => import('components/page/reporting/dashboards/didata'))
  },
  {
    path: Constants.PATH.REPORTING_SILO,
    component: lazy(() => import('components/page/reporting/dashboards/silo'))
  },
  // ------------------------------------------------- //

  // Devices/Sensors
  // ---------------
  {
    icon: EventIcon,
    label: 'Events',
    admin: true,
    path: Constants.PATH.EVENTS,
    component: lazy(() => import('page/events/Events'))
  },
  {
    icon: DeviceHubIcon,
    label: 'Devices',
    path: Constants.PATH.SENSORS_LIST,
    component: lazy(() => import('components/page/devices/'))
  },
  {
    path: util.format(Constants.PATH.SENSORS_DETAIL, ':id'),
    component: lazy(() => import('components/page/devices/detail'))
  },
  // ------------------------------------------------- //

  // Workspace management
  // ----------

  {
    id: WORKSPACE,
    icon: BusinessIcon,
    label: 'Workspaces'
  },

  {
    path: Constants.PATH.WORKSPACE_BUILDING,
    component: lazy(() => import('components/page/workspace/buildings/')),
    label: 'Buildings',
    parent: WORKSPACE
  },
  {
    path: Constants.PATH.WORKSPACE_FLOORS,
    component: lazy(() => import('components/page/workspace/floors/')),
    label: 'Floors',
    parent: WORKSPACE
  },
  {
    path: Constants.PATH.WORKSPACE_AREAS,
    component: lazy(() => import('components/page/workspace/areas/')),
    label: 'Areas',
    parent: WORKSPACE
  },
  {
    path: util.format(Constants.PATH.EDITOR, ':id'),
    component: lazy(() => import('components/page/editor/'))
  },
  // ------------------------------------------------- //
  // Sensy
  // -----
  {
    id: SENSY,
    icon: SensyIcon,
    label: 'Sensy'
  },
  {
    path: Constants.PATH.COVID_STATUS,
    component: lazy(() => import('components/page/sensy/covid/')),
    label: 'Covid Status',
    parent: SENSY
  },
  {
    path: Constants.PATH.SENSY_SURVEYS,
    component: lazy(() => import('components/page/sensy/surveys/')),
    label: 'Surveys',
    parent: SENSY
  },
  {
    path: util.format(Constants.PATH.SENSY_SURVEYS_DETAIL, ':id'),
    component: lazy(() => import('components/page/sensy/surveys/detail'))
  },
  {
    path: Constants.PATH.SENSY_NOTIFICATIONS,
    component: lazy(() => import('components/page/sensy/notifications/')),
    label: 'Notifications',
    parent: SENSY
  },
  {
    path: Constants.PATH.SENSY_SUGGESTIONS,
    component: lazy(() => import('components/page/sensy/suggestions/')),
    label: 'Suggestions',
    parent: SENSY
  },
  {
    path: Constants.PATH.SENSY_FIXIT,
    component: lazy(() => import('components/page/sensy/fixit/')),
    label: 'Fixit',
    parent: SENSY
  },
  {
    path: util.format(Constants.PATH.SENSY_SUGGESTION_DETAIL, ':id'),
    component: lazy(() => import('components/page/sensy/suggestions/detail'))
  },
  {
    path: util.format(Constants.PATH.SENSY_FIXIT_DETAIL, ':id'),
    component: lazy(() => import('components/page/sensy/fixit/detail'))
  },
  // ------------------------------------------------- //

  // ------------------------------------------------- //

  // Accounts
  // --------
  {
    id: ACCOUNT,
    icon: PersonIcon,
    label: 'Account'
  },
  {
    path: Constants.PATH.ACCOUNT_PROFILE,
    component: lazy(() => import('components/page/account/profile/')),
    label: 'Profile',
    parent: ACCOUNT
  },
  {
    path: Constants.PATH.ACCOUNT_MEMBERS,
    component: lazy(() => import('components/page/account/members/')),
    label: 'Members',
    parent: ACCOUNT
  },
  {
    path: Constants.PATH.ACCOUNT_COMPANIES,
    component: lazy(() => import('components/page/account/companies/')),
    label: 'Companies',
    parent: ACCOUNT,
    // Only available to "ergosense" permission users.
    ergosense: true
  },
  // Flow Editor
  {
    path: Constants.PATH.FLOW_EDITOR,
    component: lazy(() => import('components/page/flow-editor/')),
    label: 'Workflow Automation',
    parent: ACCOUNT
  },
  {
    path: Constants.PATH.FLOW_EDITOR_ADD,
    component: lazy(() => import('components/page/flow-editor/detail/'))
  },
  {
    path: util.format(Constants.PATH.FLOW_EDITOR_DETAIL, ':id'),
    component: lazy(() => import('components/page/flow-editor/detail/'))
  },
  {
    path: Constants.PATH.ACCOUNT_NOTIFICATIONS,
    component: lazy(() => import('components/page/account/notifications/')),
    label: 'Notifications',
    parent: ACCOUNT
  },
  {
    path: Constants.PATH.ACCOUNT_INTEGRATIONS,
    component: lazy(() => import('components/page/account/integrations/')),
    label: 'Integrations',
    parent: ACCOUNT
  },
  // ------------------------------------------------- //

  // Fallback
  // --------
  { path: '*', component: lazy(() => import('components/page/not-found/')), exact: false }
]

export const navItems = orderedRoutes.filter((i) => i.label)

export default orderedRoutes.filter((i) => i.path && i.component)
