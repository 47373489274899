// Action Types
const TOGGLE_TEMPERATURE_UNIT = 'temperature/toggleUnit';
const SET_TEMPERATURE_UNIT = 'temperature/setUnit';
const CONVERT_TEMPERATURE_VALUE = 'temperature/convertValue';

// Action Creators
export const toggleTemperatureUnit = () => ({
  type: TOGGLE_TEMPERATURE_UNIT,
});

export const setTemperatureUnit = (unit: 'C' | 'F') => ({
  type: SET_TEMPERATURE_UNIT,
  payload: unit,
});

export const convertTemperatureValue = (value: number, unit: 'C' | 'F') => ({
  type: CONVERT_TEMPERATURE_VALUE,
  payload: { value, unit },
});

// Initial State
export type TemperatureUnit = 'C' | 'F';

interface TemperatureState {
  unit: TemperatureUnit;
  convertedValue?: number; // Optional field for storing converted temperature
}

const TEMPERATURE_UNIT_KEY = 'temperatureUnit';

// Load initial state from local storage
const initialState: TemperatureState = {
  unit: (localStorage.getItem(TEMPERATURE_UNIT_KEY) as TemperatureUnit) || 'C', // Default to 'C' if not in local storage
};

// Helper Function for Conversion
const convertTemperature = (value: number, unit: 'C' | 'F') => {
  if (unit === 'C') {
    return ((value - 32) * 5) / 9; // Convert Fahrenheit to Celsius
  }
  return (value * 9) / 5 + 32; // Convert Celsius to Fahrenheit
};

// Reducer
const temperatureReducer = (
  state: TemperatureState = initialState,
  action: { type: string; payload?: any }
): TemperatureState => {
  switch (action.type) {
    case TOGGLE_TEMPERATURE_UNIT:
      const toggledUnit = state.unit === 'C' ? 'F' : 'C';
      localStorage.setItem(TEMPERATURE_UNIT_KEY, toggledUnit); // Save to local storage
      return {
        ...state,
        unit: toggledUnit,
      };
    case SET_TEMPERATURE_UNIT:
      localStorage.setItem(TEMPERATURE_UNIT_KEY, action.payload); // Save to local storage
      return {
        ...state,
        unit: action.payload,
      };
    case CONVERT_TEMPERATURE_VALUE:
      const { value, unit } = action.payload;
      const convertedValue = convertTemperature(value, unit);
      return {
        ...state,
        convertedValue,
      };
    default:
      return state;
  }
};

export default temperatureReducer;
