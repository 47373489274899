import { ACTIONS } from './../actions/login'

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import notification from './notification'
import { default as workspace } from './workspace'
import assets from './assets'
import totp from './totp'
import detail from './detail'
import loader from './loader'
import table from './table'
// import signed from './signed'
import sensy from './sensy'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { persistedReducer as persistedWorkspace } from 'actions/workspace'
import { reducer as auth } from 'actions/login'
import { reducer as api } from 'actions/api'
import { reducer as suggestions } from 'actions/suggestions'
import { reducer as fixit } from 'actions/fixit'
import { reducer as editor } from 'actions/editor'
import { reducer as workspaceViewer } from 'actions/workspace-viewer'
import { reducer as signed } from '@ergosense/ergosense-react-common/lib/actions/signed'
import { reducer as sensors } from 'actions/sensors'
import { reducer as reporting } from 'actions/reporting'
import { reducer as company } from 'actions/company'
import { reducer as flows } from 'actions/flows'
import { reducer as dashboard } from 'actions/dashboard'
import { reducer as user, persistedReducer as _user } from 'actions/user'
import temperature from './slices/temperatureSlice'

const temperaturePersistConfig = {
  key: 'temperature',
  storage,
  // You can add whitelist or blacklist if needed
};

const persistedTemperature = persistReducer(temperaturePersistConfig, temperature);

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  loader,
  detail,
  totp,
  auth,
  notification,
  workspace,
  dashboard,
  assets,
  sensors,
  table,
  signed,
  user,
  api,
  sensy,
  suggestions,
  editor,
  workspaceViewer,
  fixit,
  reporting,
  company,
  flows,
  _workspace: persistedWorkspace,
  _user,
  temperature: persistedTemperature
})

const rootReducer = (history) => (state, action) => {
  // Reset user store on logout
  if (action.type === ACTIONS.LOGOUT) { state = undefined }

  return appReducer(history)(state, action)
}

export default rootReducer

export const reactRootReducer = (state, action) => ({
  auth: auth(state.auth, action)
})
