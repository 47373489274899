import API, { graphqlOperation } from '@aws-amplify/api'
import loader from './loader'
import dayjs from 'dayjs'
import { getActiveCompanyId } from 'helpers/'
import axios from 'axios'
import { signCompanyContent } from '@ergosense/ergosense-react-common/lib/actions/signed'
import { createCompanyContentUrl } from '@ergosense/ergosense-react-common/lib/helpers/'

export const GROUP = {
  FIFTEEN_MINUTES: 'FIFTEEN_MINUTES',
  HOUR: 'HOUR',
  DAY: 'DAY',
  MONTH: 'MONTH'
}

export const AGGREGATE_FN = {
  MEAN: 'mean',
  SUM: 'sum'
}


/**
 * List of action strings
 */
export const ACTION = {
  LOAD_WORKSPACE_VIEW: 'load-workspace-view',
  GET_COMFORT_BREAKDOWN: 'get-comfort-breakdown',
  GET_POLLUTION_BREAKDOWN: 'get-pollution-breakdown',
  GET_PRODUCTIVITY_BREAKDOWN: 'get-productivity-breakdown',
  GET_MEETING_UTILIZATION_INFO: 'get-meeting-utilization-info',
  GET_MEETING_OCCUPANCY_INFO: 'get-meeting-occupancy-info',
  GET_WEEKLY_WORKSPACE_UTILIZATION_DISTRIBUTION: 'get-weekly-workspace-utilization-distribution',
  GET_WEEKLY_DISTRIBUTION_DESKS: 'get-weekly-distribution-desks',
  GET_MEETING_UTILIZATION_BY_AREA: 'get-meeting-utilization-by-area',
  GET_WORKSPACE_UTILIZATION_INFO: 'get-workspace-utilization-info',
  GET_CO2_INFO: 'get-co2-info',
  GET_CO2_MEAN_MAX: 'get-co2-mean-max',
  GET_CO2_PER_AREA: 'get-co2-per-area',
  GET_LUX_PER_AREA: 'get-lux-per-area',
  GET_CORRELATED_CO2: 'get-correlated-co2',
  GET_WORKSPACE_UTILIZATION_BY_AREA: 'get-workspace-utilization-by-area',
  GET_WEEKLY_MEETING_UTILIZATION_DISTRIBUTION: 'get-weekly-meeting-utilization-distribution',
  GET_WORKSPACE_UTILIZATION_DAILY: 'get-workspace-utilization-daily',
  GET_DAILY_OCCUPANCY_BREAKDOWN: 'get-daily-occupancy',
  GET_AREA_BREAKDOWN: 'get-area-breakdown',
  GET_MEASUREMENTS: 'reporting-get-measurements',
  GET_PEAK_CAPACITY_RATES: 'peak-capacity-rates',
  GET_IN_OUT: 'get-in-out',
  GET_REPORT: 'get-report',
  // COMFORT
  GET_TEMPERATURE_SUMMARY: 'get-temperature-summary',
  GET_TEMPERATURE_PER_AREA: 'get-temperature-per-area',
  GET_SOUND_LEVELS_PER_AREA: 'get-sound-levels-per-area',
  GET_AVERAGE_HUMIDITY: 'get-average-humidity',
  GET_RELATIVE_HUMIDITY: 'get-relative-humidity',
  // TRAFFIC
  GET_DAILY_TRAFFIC: 'get-daily-traffic',
  // CUSTOM
  GET_STANDARD_BANK_UTILIZATION: 'get-standard-bank-utilization',
  GET_TECHDATA: 'get-techData',
  GET_OCCUPIED_DESKS: 'get-occupied-desks',

  GET_BUTTON_EVENTS: 'get-button-events',
  GET_CON_MEAN: 'get-con-mean',
  GET_TEMP_HUMIDITY: 'get-temp-humidity',
}

/**
 * List of loading action strings
 */
export const LOAD = {
  ...ACTION
}

export const getConMeanQuery = `
  query ($areaId: [Int!], $floorId: [Int!], $buildingId: [Int!], $from: Int!, $to: Int!) {
    measurementMean: getMeasurementsMean (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to) {
      conPm1
      conPm25
      conPm4
      conPm10
    }
  }
`

export const getTempHumidityQuery = `
  query ($deskId: Int, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $groupBy: String) {
    tempHum: getMeasurements (deskId: $deskId, from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: $groupBy) {
      time  
      humidity
      temp
    }
  }
`


export const getPeakCapacityRatesQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!],) {
    capacityRates: getCountFrequency(buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, workhours: true) {
      bins
      counts
      total
    }
  }
`

export const getMeasurementsQuery = `
  query ($deskId: Int, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $groupBy: String, $fill: Boolean, $fn: String) {
    measurements: getMeasurements (deskId: $deskId, from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: $groupBy, fill: $fill, fn: $fn) {
      time
      co2
      humidity
      lux
      mic
      workspaceUtilization
      meetingUtilization
      temp
      tvoc
      count
      massPm1
      massPm10
      massPm25
      massPm4
      battery
      in
      out
    }
  }
`

export const getAreaBreakdownQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $workhours: Boolean, $measurement: String!, $aggregateOn: String) {
percentileSummary: getPercentileSummary (measurement: $measurement, from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: $workhours) {
      deviceCount
      capacity
      mean
      firstQuantile
      secondQuantile
      thirdQuantile
      fourthQuantile
      ninetiethPercentile
    }

aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: $workhours, groupBy: "areaId", only: [$measurement, "capacity"], aggregateOn: $aggregateOn, fn: "mean") {
      areaId
      area {
        name
        floor { name }
        building { name }
      }
      capacity
      workspaceUtilization
      meetingUtilization
      co2
      tvoc
      temp
      humidity
      lux
      mic
      in
      massPm10
      massPm25
      aqi
    }

aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: $workhours, groupBy: "areaId", only: [$measurement, "capacity"], aggregateOn: $aggregateOn, fn: "max") {
      areaId
      area {
        name
        floor { name }
        building { name }
      }
      capacity
      workspaceUtilization
      meetingUtilization
      co2
      tvoc
      temp
      humidity
      lux
      mic
      in
      massPm10
      massPm25
      aqi
    }
  }
`

export const getMeetingUtilizationByAreaQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["meetingUtilization"], aggregateOn: "mean", fn: "mean") {
      areaId
      area {
        capacity
      }
      meetingUtilization
    }

    aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["meetingUtilization"], aggregateOn: "mean", fn: "max") {
      areaId
      area {
        capacity
      }
      meetingUtilization
    }
  }
`

export const getMeetingOccupancyInfoQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    percentileSummary: getPercentileSummary (measurement: "meetingOccupancy", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      deviceCount
      mean
      fourthQuantile
      ninetiethPercentile
    }
  }
`

export const getDailyOccupancyBreakdownQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    dailyOccupancy: getDailyOccupancyBreakdown (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      bins
      occupancyCounts
      hours
    }
  }
`

export const getWeeklyWorkspaceUtilizationDistributionQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    weeklyDistribution: getWeeklyDistribution (fn: "mean", measurement: "workspaceUtilization", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      hour
      weekDay
      workspaceUtilization
    }
  }
`

export const getWeeklyMeetingUtilizationDistributionQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    weeklyDistribution: getWeeklyDistribution (fn: "max", measurement: "in", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      hour
      weekDay
      in
    }
  }
`

export const getMeetingUtilizationInfoQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    percentileSummary: getPercentileSummary (measurement: "meetingUtilization", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      capacity
      mean
      fourthQuantile
      ninetiethPercentile
    }
  }
`

export const getWorkspaceUtilizationByAreaQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["workspaceUtilization"], aggregateOn: "mean", fn: "mean") {
      areaId
      workspaceUtilization
    }

    aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["workspaceUtilization"], aggregateOn: "mean", fn: "max") {
      areaId
      workspaceUtilization
    }
  }
`

export const getWorkspaceUtilizationInfoQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    percentileSummary: getPercentileSummary (measurement: "workspaceUtilization", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      deviceCount
      mean
      fourthQuantile
      ninetiethPercentile
    }
  }
`

export const getWorkspaceUtilizationDailyQuery = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    dailyMeasurements: getDailyMeasurements (only: ["workspaceUtilization"], from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
      weekDay
      workspaceUtilization
      workspaceUtilizationMax
    }
  }
`

export const getComfortBreakdownQuery = `
  query ($areaId: Int, $floorId: Int, $buildingId: Int, $from: Int!, $to: Int!) {
    thermalComfortBreakdown: getThermalComfortBreakdown (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, workhours: true) {
      hourlySoundLevelMeanByArea {
        areaId
        hour
        mic
      }

      hourlySoundLevelMaxByArea {
        areaId
        hour
        mic
      }

      hourlySoundLevelMean {
        hour
        mic
      }

      hourlySoundLevelMax {
        hour
        mic
      }

      temperatureMeanByArea {
        areaId
        temp
      }

      temperatureMaxByArea {
        areaId
        temp
      }

      relativeHumidity {
        base
        mean
        min
        max
      }

      humidityMean
      humidityMax

      temperatureMeanByFloor {
        floorId
        temp
      }

      temperatureMaxByFloor {
        floorId
        temp
      }

      temperatureSummary {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }
    }
  }
`

export const getPollutionBreakdownQuery = `
  query ($areaId: [Int!], $floorId: [Int!], $buildingId: [Int!], $from: Int!, $to: Int!) {
    pollutionBreakdown: getPollutionBreakdown (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to) {
      massPm1Mean
      massPm25Mean
      massPm4Mean
      massPm10Mean

      tvocMeanPerDay {
        weekDay
        tvoc
      }

      tvocMaxPerDay {
        weekDay
        tvoc
      }

      massPm1MeanPerHour {
        hour
        massPm1
      }

      massPm25MeanPerHour {
        hour
        massPm25
      }

      massPm4MeanPerHour {
        hour
        massPm4
      }

      massPm10MeanPerHour {
        hour
        massPm10
      }
    }
  }
`

export const getProductivityBreakdownQuery = `
  query ($areaId: Int, $floorId: Int, $buildingId: Int, $from: Int!, $to: Int!) {
    productivityBreakdown: getProductivityBreakdown (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to) {
      hourlyMeanCo2 {
        hour
        co2
      }

      hourlyMaxCo2 {
        hour
        co2
      }

      hourlyMeanLuxByArea {
        hour
        lux
        areaId
      }

      meanPerArea {
        areaId
        co2
      }

      meanCo2
      maxCo2

      correlatedUtilization {
        base
        mean
        max
        min
      }
    }
  }
`

export const getInOutWeekly = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      inOut: getDailyMeasurements (only: ["in", "out"], fn: "sum", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
        weekDay
        in
        out
      }
    }
  `

  return loader.load(LOAD.GET_IN_OUT, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_IN_OUT,
        ...result.data
      }))
  })
}

export const getRelativeHumidity = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      correlatedMeasurements: getCorrelatedMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, baseMeasurement: "temp", comparitiveMeasurement: "humidity") {
        base
        mean
        max
        min
      }
    }
  `

  return loader.load(LOAD.GET_RELATIVE_HUMIDITY, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_RELATIVE_HUMIDITY,
        ...result.data
      }))
  })
}

export const getAverageHumidity = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, only: ["humidity"], aggregateOn: "mean", fn: "mean") {
        humidity
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, only: ["humidity"], aggregateOn: "mean", fn: "max") {
        humidity
      }
    }
  `

  return loader.load(LOAD.GET_AVERAGE_HUMIDITY, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_AVERAGE_HUMIDITY,
        ...result.data
      }))
  })
}

export const getTemperaturePerArea = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["temp"], aggregateOn: "mean", fn: "mean") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        temp
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["temp"], aggregateOn: "mean", fn: "max") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        temp
      }
    }
  `

  return loader.load(LOAD.GET_TEMPERATURE_PER_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_TEMPERATURE_PER_AREA,
        ...result.data
      }))
  })
}

export const getTemperatureSummary = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      quantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "temp") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }
    }
  `

  return loader.load(LOAD.GET_TEMPERATURE_SUMMARY, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_TEMPERATURE_SUMMARY,
        ...result.data
      }))
  })
}

export const getSoundLevelsPerArea = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["mic"], aggregateOn: "mean", fn: "mean") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        mic
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["mic"], aggregateOn: "mean", fn: "max") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        mic
      }
    }
  `

  return loader.load(LOAD.GET_SOUND_LEVELS_PER_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_SOUND_LEVELS_PER_AREA,
        ...result.data
      }))
  })
}

export const getStandardBankUtilization = (buildingId, floorId, areaId) => {
  const from = dayjs().subtract(7, 'days').startOf('week').unix()
  const fromLast = dayjs().subtract(1, 'hour').startOf('hour').unix()
  const to = dayjs().unix()

  const query = `
    query ($fromLast: Int!, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      percentileSummary: getPercentileSummary (measurement: "workspaceUtilization", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
        deviceCount
        mean
        fourthQuantile
        ninetiethPercentile
      }

      aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["workspaceUtilization"], aggregateOn: "mean", fn: "mean") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        workspaceUtilization
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["workspaceUtilization"], aggregateOn: "mean", fn: "max") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        workspaceUtilization
      }

      weeklyDistribution: getWeeklyDistribution (fn: "mean", measurement: "workspaceUtilization", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
        hour
        weekDay
        workspaceUtilization
      }

      lastMeasurements: getLastMeasurements (from: $fromLast, to: $to, buildingId: $buildingId, floorId: $floorId) {
        temp
        lux
        mic
        co2
        humidity
      }
    }
  `

  return loader.load(LOAD.GET_STANDARD_BANK_UTILIZATION, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, fromLast, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_STANDARD_BANK_UTILIZATION,
        ...result.data
      }))
  })
}

export const getTechDataDemo = (buildingId, floorId, areaId) => {
  const from = dayjs().subtract(7, 'days').startOf('week').unix()
  const fromLast = dayjs().subtract(1, 'hour').startOf('hour').unix()
  const to = dayjs().unix()

  const query = `
    query ($fromLast: Int!, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      tempQuantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "temp") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }
      luxQuantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "lux") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }
      humidityQuantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "humidity") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }
      micQuantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "mic") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }

      co2QuantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "co2") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }
      tvocQuantileSummary: getQuantileSummary (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, measurement: "tvoc") {
        min
        minAreaId
        minTime
        max
        maxAreaId
        maxTime
        mean
        firstQuantile
        thirdQuantile
      }

      soundMean: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "mean", only: ["mic"]) {
        hour
        mic
      }

      soundMax: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "max", only: ["mic"]) {
        hour
        mic
      }

      co2MaxHourly: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "max", only: ["co2"]) {
        hour
        co2
      }

      co2MeanHourly: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "mean", only: ["co2"]) {
        hour
        co2
      }

      tvocMaxHourly: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "max", only: ["tvoc"]) {
        hour
        tvoc
      }

      tvocMeanHourly: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "mean", only: ["tvoc"]) {
        hour
        tvoc
      }

      aggregateMeasurements: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["workspaceUtilization"], aggregateOn: "mean", fn: "mean") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        workspaceUtilization
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["workspaceUtilization"], aggregateOn: "mean", fn: "max") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        workspaceUtilization
      }

      weeklyDistribution: getWeeklyDistribution (fn: "mean", measurement: "workspaceUtilization", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
        hour
        weekDay
        workspaceUtilization
      }

      massPm: getMeasurements (deskId: null, from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: "DAY", fill: true, fn: "mean", workhours: false) {
        time
        massPm1
        massPm10
        massPm25
        massPm4
      }

      lastMeasurements: getLastMeasurements (from: $fromLast, to: $to, buildingId: $buildingId, floorId: $floorId) {
        temp
        massPm1
        massPm25
        massPm4
        massPm10
        lux
        mic
        co2
        tvoc
        humidity
        in
      }
    }
  `

  return loader.load(LOAD.GET_TECHDATA, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, fromLast, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_TECHDATA,
        ...result.data
      }))
  })
}

export const getCo2Info = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      percentileSummary: getPercentileSummary (measurement: "co2", from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true) {
        deviceCount
        mean
        fourthQuantile
        ninetiethPercentile
      }
    }
  `

  return loader.load(LOAD.GET_CO2_INFO, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_CO2_INFO,
        ...result.data.percentileSummary
      }))
  })
}

export const getCo2MeanMax = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      mean: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "mean", only: ["co2"]) {
        hour
        co2
      }

      max: getHourlyMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, fn: "max", only: ["co2"]) {
        hour
        co2
      }
    }
  `

  return loader.load(LOAD.GET_CO2_MEAN_MAX, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_CO2_MEAN_MAX,
        co2Mean: result.data.mean,
        co2Max: result.data.max
      }))
  })
}

export const getCorrelatedCo2 = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      correlated: getCorrelatedMeasurements (buildingId: $buildingId, floorId: $floorId, areaId: $areaId, from: $from, to: $to, baseMeasurement: "workspaceUtilization", comparitiveMeasurement: "co2") {
        base
        mean
        max
        min
      }
    }
  `

  return loader.load(LOAD.GET_CORRELATED_CO2, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_CORRELATED_CO2,
        ...result.data
      }))
  })
}

export const getCo2PerArea = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      aggregateMeasurementsMean: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["co2"], aggregateOn: "mean", fn: "mean") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        co2
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["co2"], aggregateOn: "mean", fn: "max") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        co2
      }
    }
  `

  return loader.load(LOAD.GET_CO2_PER_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_CO2_PER_AREA,
        ...result.data
      }))
  })
}

export const getLuxPerArea = (from, to, buildingId, floorId, areaId) => {
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
      aggregateMeasurementsMean: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["lux"], aggregateOn: "mean", fn: "mean") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        lux
      }

      aggregateMeasurementsMax: getAggregateMeasurements (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: true, groupBy: "areaId", only: ["lux"], aggregateOn: "mean", fn: "max") {
        areaId
        area {
          name
          floor { name }
          building { name }
        }
        lux
      }
    }
  `

  return loader.load(LOAD.GET_LUX_PER_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_LUX_PER_AREA,
        ...result.data
      }))
  })
}

export const getMeasurements = (from, to, buildingId, floorId, areaId, deskId, groupBy, aggregateFn = AGGREGATE_FN.MEAN, workhours = false) => {
  const query = `
    query ($deskId: Int, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $groupBy: String, $fill: Boolean, $fn: String, $workhours: Boolean) {
      measurements: getMeasurements (deskId: $deskId, from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: $groupBy, fill: $fill, fn: $fn, workhours: $workhours) {
        time
        co2
        humidity
        lux
        mic
        workspaceUtilization
        meetingUtilization
        temp
        tvoc
        count
        massPm1
        massPm10
        massPm25
        massPm4
        battery
        aqi
        in
        out
        heartbeat
      }
    }
  `

  const queryCount = `
    query ($deskId: Int, $from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $groupBy: String, $fill: Boolean, $workhours: Boolean) {
      measurements: getMeasurements (deskId: $deskId, from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: $groupBy, fill: $fill, fn: "mean", workhours: $workhours) {
        count
      }
    }
  `

  return loader.load(LOAD.GET_MEASUREMENTS, (dispatch) => {
    // Limit lookup based on grouping
    // TODO this should happen in backend
    if (groupBy === GROUP.FIFTEEN_MINUTES) {
      from = Math.max(from, to - (24 * 60 * 60))
    } else if (groupBy === 'LIVE') {
      from = Math.max(from, to - (24 * 60 * 60))
      deskId = deskId ?? 1
    } else if (groupBy === GROUP.HOUR) {
      from = Math.max(from, to - (30 * 24 * 60 * 60))
    }

    const now = dayjs().unix()

    to = Math.min(to, now)

    // Ensure no strings specified
    deskId = deskId ? parseInt(deskId, 10) : null

    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId, deskId: deskId, groupBy, fill: true, fn: aggregateFn, workhours }))
      .then((result) =>
        dispatch({
          type: ACTION.GET_MEASUREMENTS,
          measurements: result.data.measurements
        })
      )
  })
}

let test

export const getOccupiedDesks = (from, to, buildingId, floorId, areaId, workhours, current = true) => {
  const fromCurrent = dayjs().subtract(1, 'hour').startOf('hour').unix()
  const query = `
    query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $workhours:  Boolean) {
      occupiedDesks: getUnoccupiedDesks(from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: $workhours) {
        mean
        max
        min
        daysUnoccupied
        days
        daily {
          weekDay
          mean
          max
          min
        }
      }

      ${current && `occupiedDesksCurrent: getUnoccupiedDesks(from: ${fromCurrent}, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, current: true workhours: $workhours) {
        mean
        max
      }` || ''}
    }
  `
  return loader.load(LOAD.GET_OCCUPIED_DESKS, (dispatch) => {
    test = API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId, workhours }))
    try {
      void test
    } catch (error) {
      console.log(error)
      // If the error is because the request was cancelled you can confirm here.
      if (API.isCancel(error)) {
        console.log('cancle error', error.message) // "my message for cancellation"
        // handle user cancellation logic
      }
    }
    test.then((result) => dispatch({
      type: ACTION.GET_OCCUPIED_DESKS,
      occupiedDesks: result.data.occupiedDesks,
      occupiedDesksCurrent: result.data.occupiedDesksCurrent
    }))
    test.catch(err => console.log('err', err))
    test.then(res => console.log('res', res))
    if (test) {
      console.log('cancelling query', API.cancel(test, 'cancelled'))
    }
    return test
  })
}

export const getPeakCapacityReates = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_PEAK_CAPACITY_RATES, (dispatch) => {
    return API.graphql(graphqlOperation(getPeakCapacityRatesQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_PEAK_CAPACITY_RATES,
        ...result.data
      }))
  })
}

export const getAreaBreakdown = (from, to, buildingId, floorId, areaId, measurement, aggregateOn, workhours) => {
  return loader.load(LOAD.GET_AREA_BREAKDOWN, (dispatch) => {
    dispatch({ type: ACTION.GET_AREA_BREAKDOWN })

    return API.graphql(graphqlOperation(getAreaBreakdownQuery, { from, to, buildingId, floorId, areaId, measurement, aggregateOn, workhours }))
      .then((result) => dispatch({
        type: ACTION.GET_AREA_BREAKDOWN,
        ...result.data
      }))
  })
}

export const getDailyOccupancyBreakdown = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_DAILY_OCCUPANCY_BREAKDOWN, (dispatch) => {
    return API.graphql(graphqlOperation(getDailyOccupancyBreakdownQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_DAILY_OCCUPANCY_BREAKDOWN,
        ...result.data
      }))
  })
}

export const getWorkspaceUtilizationDailyMeasurements = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_WORKSPACE_UTILIZATION_DAILY, (dispatch) => {
    return API.graphql(graphqlOperation(getWorkspaceUtilizationDailyQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_WORKSPACE_UTILIZATION_DAILY,
        ...result.data
      }))
  })
}

export const getMeetingUtilizationByArea = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_MEETING_UTILIZATION_BY_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(getMeetingUtilizationByAreaQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_MEETING_UTILIZATION_BY_AREA,
        ...result.data
      }))
  })
}

export const getWorkspaceUtilizationByArea = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_WORKSPACE_UTILIZATION_BY_AREA, (dispatch) => {
    return API.graphql(graphqlOperation(getWorkspaceUtilizationByAreaQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_WORKSPACE_UTILIZATION_BY_AREA,
        ...result.data
      }))
  })
}

export const getMeetingUtilizationInfo = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_MEETING_UTILIZATION_INFO, (dispatch) => {
    return API.graphql(graphqlOperation(getMeetingUtilizationInfoQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_MEETING_UTILIZATION_INFO,
        ...result.data.percentileSummary
      }))
  })
}

export const getMeetingOccupancyInfo = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_MEETING_OCCUPANCY_INFO, (dispatch) => {
    return API.graphql(graphqlOperation(getMeetingOccupancyInfoQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_MEETING_OCCUPANCY_INFO,
        ...result.data.percentileSummary
      }))
  })
}

export const getWeeklyWorkspaceUtilizationDistribution = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_WEEKLY_WORKSPACE_UTILIZATION_DISTRIBUTION, (dispatch) => {
    return API.graphql(graphqlOperation(getWeeklyWorkspaceUtilizationDistributionQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_WEEKLY_WORKSPACE_UTILIZATION_DISTRIBUTION,
        weeklyDistribution: result.data.weeklyDistribution
      }))
  })
}

export const getWeeklyMeetingUtilizationDistribution = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_WEEKLY_MEETING_UTILIZATION_DISTRIBUTION, (dispatch) => {
    return API.graphql(graphqlOperation(getWeeklyMeetingUtilizationDistributionQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_WEEKLY_MEETING_UTILIZATION_DISTRIBUTION,
        weeklyDistribution: result.data.weeklyDistribution
      }))
  })
}

export const getWorkspaceUtilizationInfo = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_WORKSPACE_UTILIZATION_INFO, (dispatch) => {
    return API.graphql(graphqlOperation(getWorkspaceUtilizationInfoQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_WORKSPACE_UTILIZATION_INFO,
        ...result.data.percentileSummary
      }))
  })
}

export const getComfortBreakdown = (from, to, building, floor, area) => {
  return loader.load(LOAD.GET_COMFORT_BREAKDOWN, (dispatch) => {
    return API.graphql(graphqlOperation(getComfortBreakdownQuery, { from, to, buildingId: building, floorId: floor, areaId: area }))
      .then((result) => dispatch({
        type: ACTION.GET_COMFORT_BREAKDOWN,
        ...result.data.thermalComfortBreakdown
      }))
  })
}

export const getPollutionBreakdown = (areaId, floorId, buildingId, from, to) => {
  return loader.load(LOAD.GET_POLLUTION_BREAKDOWN, (dispatch) => {
    return API.graphql(graphqlOperation(getPollutionBreakdownQuery, { areaId, floorId, buildingId, from, to }))
      .then((result) => {
        dispatch({
          type: ACTION.GET_POLLUTION_BREAKDOWN,
          pollutionBreakdown: result.data.pollutionBreakdown // explicitly set only the needed data
        })
      })
  })
}


export const getConMean = (areaId, floorId, buildingId, from, to) => {
  return loader.load(LOAD.GET_CON_MEAN, (dispatch) => {
    return API.graphql(graphqlOperation(getConMeanQuery, { areaId, floorId, buildingId, from, to }))
      .then((result) => {
        dispatch({
          type: ACTION.GET_CON_MEAN,
          payload: result.data.measurementMean
        })
      })
  })
}

export const getTempHumidity = (areaId, floorId, buildingId, from, to, groupBy) => {
  return loader.load(LOAD.GET_TEMP_HUMIDITY, (dispatch) => {
    return API.graphql(graphqlOperation(getTempHumidityQuery, { areaId, floorId, buildingId, from, to, groupBy}))
      .then((result) => {
        dispatch({
          type: ACTION.GET_TEMP_HUMIDITY,
          payload: result.data.tempHum
        })
      })
  })
}



export const getProductivityBreakdown = (from, to, buildingId, floorId, areaId) => {
  return loader.load(LOAD.GET_PRODUCTIVITY_BREAKDOWN, (dispatch) => {
    return API.graphql(graphqlOperation(getProductivityBreakdownQuery, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_PRODUCTIVITY_BREAKDOWN,
        ...result.data.productivityBreakdown
      }))
  })
}

export const getDailyTraffic = (from, to, buildingId, floorId, areaId, workhours) => {
  const occupantsFrom = dayjs.unix(from).startOf('d').unix()
  const occupantsTo = dayjs.unix(to).endOf('d').unix()
  const query = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $workhours: Boolean ) {
    dailyTraffic: getDailyTraffic (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: $workhours) {
      mean
      max
      maxDay {
        time
        value
        hourly {
          time
          value
        }
      }
      daily {
        mean
        max
        weekDay
      }
      areas {
        mean {
          in
          areaId
        }
        max {
          in
          areaId
        }
      }
    }
    occupantsHour: getMeasurements (from: ${occupantsFrom}, to: ${to}, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: "HOUR", fill: false, fn: "sum") {
      time
      in
      out
    }
    occupants: getMeasurements (from: ${occupantsFrom}, to: ${occupantsTo}, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, groupBy: "FIFTEEN_MINUTES", fill: false, fn: "sum") {
      time
      count
      in
      out
    }
}

`

  return loader.load(LOAD.GET_DAILY_TRAFFIC, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId, workhours }))
      .then((result) => dispatch({
        type: ACTION.GET_DAILY_TRAFFIC,
        ...result.data
      }))
  })
}

export const getWeeklyDistributionDesks = (from, to, buildingId, floorId, areaId, workhours) => {
  const query = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $workhours: Boolean ) {
    weeklyDistributionDesks: getWeeklyDistributionDesks (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, workhours: $workhours) {
      hour
      weekDay
      desks
    }
  }
`

  return loader.load(LOAD.GET_WEEKLY_DISTRIBUTION_DESKS, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId, workhours }))
      .then((result) => dispatch({
        type: ACTION.GET_WEEKLY_DISTRIBUTION_DESKS,
        ...result.data
      }))
  })
}

export const getReport = (from, to, buildingId, floorId, areaId, policies, inputs) => {
  const query = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!], $policies: [String!], $inputs: String) {
report: generateReport (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId, policies: $policies, inputs: $inputs) {
      env {
          building 
          floor 
          desk 
          area 
          time
          value
          field
      }
      meeting {
        unoccupiedRooms {
          utilization
          count
          occupancy
          area
          capacity
        }
        underutilizedRooms {
          utilization
          count
          occupancy
          area
          capacity
        }
        underutilizedCost
        overutilizedRooms {
          utilization
          count
          occupancy
          area
          capacity
        }
        roomCountFrequency {
          area
          total
          bins
          counts
        }
        density
        projection
      }
      workspace {
          unusedFloorspace
          unusedFloorspaceCost
          unusedFloorspaceDesks
          areaCount
          areas {
            building
            floor
            area
            desks
            util
            unusedFloorspace
            unusedFloorspaceCost
            unusedFloorspaceDesks
          }
      }
    }
  }
`

  return loader.load(LOAD.GET_REPORT, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId, policies, inputs: JSON.stringify(inputs) }))
      .then((result) => dispatch({
        type: ACTION.GET_REPORT,
        ...result.data
      }))
  })
}

export const getButtonEvents = (from, to, buildingId, floorId, areaId) => {
  const query = `
  query ($from: Int!, $to: Int!, $buildingId: [Int!], $floorId: [Int!], $areaId: [Int!]) {
    events: getButtonEvents (from: $from, to: $to, buildingId: $buildingId, floorId: $floorId, areaId: $areaId) {
      time
      temp
      humidity
      battery
      area
      floor
      building
    }
  }
`

  return loader.load(LOAD.GET_BUTTON_EVENTS, (dispatch) => {
    return API.graphql(graphqlOperation(query, { from, to, buildingId, floorId, areaId }))
      .then((result) => dispatch({
        type: ACTION.GET_BUTTON_EVENTS,
        ...result.data
      }))
  })
}

export const loadWorkspaceView = (floorId) => {
  return loader.load(LOAD.LOAD_WORKSPACE_VIEW, async (dispatch, getState) => {
    // No floor, nothing more to do
    if (!floorId) return Promise.resolve()

    // Grab the active company ID
    const state = getState()

    const companyId = getActiveCompanyId(state)

    const signed = await dispatch(signCompanyContent())

    // Create a signed company content URL which refers to the floors
    // geojson file
    // TODO have a cache key, right now it's not caching...we need a cache key
    // and a way to force invalidation.
    const url = createCompanyContentUrl(companyId, `${floorId}.geojson`, { versionKey: dayjs().unix(), ...signed })

    // Query the floor geojson and desk data at the same time
    try {
      const content = await axios.get(url)

      const sensors = await API.graphql(graphqlOperation(`
        query ($from: Int!, $to: Int!, $floor: Int!, $fetch: Int!) {
          getSensors (floorId: [$floor], fetch: $fetch) {
            data {
              id
              lastEvent
              type
              reportInto
              reportIntoUUID
            }
          }

          getLastMeasurementsGrouped (from: $from, to: $to, floorId: $floor) {
            deskId
            occupancy
          }
        }
      `, { floor: floorId, from: dayjs().subtract(30, 'm').unix(), to: dayjs().unix(), fetch: 500 }))

      // Loop through all features and embed sensor status properties
      const geojson = {
        ...content.data,
        features: (content.data.features || [])
      }

      dispatch({
        type: ACTION.LOAD_WORKSPACE_VIEW,
        geojson,
        sensors: sensors.data.getSensors.data,
        measurements: sensors.data.getLastMeasurementsGrouped
      })
    } catch (e) {
      // "403" error usually means the file does not exist
      if (e.response && e.response.status === 403) {
        dispatch({ type: ACTION.LOAD_WORKSPACE_VIEW })
      } else {
        // Forward exception, still valid error
        throw e
      }
    }
  })
}

export const reducer = (state = {}, action) => {
  // eslint-disable-next-line
  const { type, ...rest } = action

  switch (type) {
    // WORKSPACE VIEWER LOGIC
    // ----------------------
    case ACTION.LOAD_WORKSPACE_VIEW:
      return {
        ...state,
        viewer: rest
      }
    // ----------------------
    case ACTION.GET_PEAK_CAPACITY_RATES:
      return {
        ...state,
        capacityRates: rest.capacityRates
      }
    case ACTION.GET_COMFORT_BREAKDOWN:
      return {
        comfort: {
          ...rest
        }
      }
    case ACTION.GET_POLLUTION_BREAKDOWN:
      return {
        ...state,
        airQuality: {
          ...state.airQuality,
          pollutionBreakdown: action.pollutionBreakdown || [] // fallback to empty array if undefined
        }
      };



    case ACTION.GET_CON_MEAN:
      return {
        ...state,
        numCon: {
          ...(state.numCon || {}),
          measurementMean: action.payload // Store the data directly
        }
      };

      case ACTION.GET_TEMP_HUMIDITY:
        return {
          ...state,
          tempHum: {
            ...(state.tempHum || {}),
            tempHum: action.payload // Store the data directly
          }
        };
  

    case ACTION.GET_PRODUCTIVITY_BREAKDOWN:
      return {
        productivity: {
          ...rest
        }
      }
    case ACTION.GET_MEETING_UTILIZATION_INFO:
      return {
        ...state,
        meetingUtilizationInfo: rest
      }
    case ACTION.GET_MEETING_OCCUPANCY_INFO:
      return {
        ...state,
        meetingOccupancyInfo: rest
      }
    case ACTION.GET_WEEKLY_MEETING_UTILIZATION_DISTRIBUTION:
      return {
        ...state,
        weeklyMeetingUtilizationDistribution: rest.weeklyDistribution
      }
    case ACTION.GET_MEETING_UTILIZATION_BY_AREA:
      return {
        ...state,
        meetingUtilizationByArea: rest
      }

    // Workspace utilization reports
    case ACTION.GET_DAILY_OCCUPANCY_BREAKDOWN:
      return {
        ...state,
        dailyOccupancy: rest.dailyOccupancy
      }
    case ACTION.GET_WEEKLY_WORKSPACE_UTILIZATION_DISTRIBUTION:
      return {
        ...state,
        weeklyWorkspaceUtilizationDistribution: rest.weeklyDistribution
      }
    case ACTION.GET_WEEKLY_DISTRIBUTION_DESKS:
      return {
        ...state,
        weeklyDistributionDesks: rest.weeklyDistributionDesks
      }
    case ACTION.GET_WORKSPACE_UTILIZATION_INFO:
      return {
        ...state,
        workspaceUtilizationInfo: rest
      }

    case ACTION.GET_WORKSPACE_UTILIZATION_BY_AREA:
      return {
        ...state,
        workspaceUtilizationByArea: rest
      }
    case ACTION.GET_WORKSPACE_UTILIZATION_DAILY:
      return {
        ...state,
        workspaceUtilizationDailyMeasurements: rest.dailyMeasurements
      }
    case ACTION.GET_IN_OUT:
      return {
        ...state,
        inOut: rest.inOut
      }
    case ACTION.GET_OCCUPIED_DESKS:
      return {
        ...state,
        occupiedDesks: rest.occupiedDesks,
        occupiedDesksCurrent: rest.occupiedDesksCurrent
      }

    // AREA BREAKDOWN
    // --------------
    case ACTION.GET_AREA_BREAKDOWN:
      return {
        ...state,
        areaBreakdown: {
          mean: rest.aggregateMeasurements,
          max: rest.aggregateMeasurementsMax,
          percentile: rest.percentileSummary
        }
      }
    // MEASUREMENT LOOKUP
    // ------------------
    case ACTION.GET_MEASUREMENTS:
      return {
        ...state,
        measurements: rest.measurements
      }

    // COMFORT REPORT
    // --------------
    case ACTION.GET_TEMPERATURE_SUMMARY:
      return {
        ...state,
        comfort: {
          ...state.comfort,
          temperatureSummary: rest
        }
      }
    case ACTION.GET_TEMPERATURE_PER_AREA:
      return {
        ...state,
        comfort: {
          ...state.comfort,
          temperaturePerArea: rest
        }
      }
    case ACTION.GET_SOUND_LEVELS_PER_AREA:
      return {
        ...state,
        comfort: {
          ...state.comfort,
          soundLevelsPerArea: rest
        }
      }
    case ACTION.GET_AVERAGE_HUMIDITY:
      return {
        ...state,
        comfort: {
          ...state.comfort,
          humidity: rest
        }
      }
    case ACTION.GET_RELATIVE_HUMIDITY:
      return {
        ...state,
        comfort: {
          ...state.comfort,
          relativeHumidity: rest
        }
      }

    // PRODUCTIVITY REPORT
    // -------------------
    case ACTION.GET_CO2_INFO:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          info: rest
        }
      }
    case ACTION.GET_CO2_MEAN_MAX:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          ...rest
        }
      }
    case ACTION.GET_CO2_PER_AREA:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          meanPerArea: rest.aggregateMeasurementsMean,
          maxPerArea: rest.aggregateMeasurementsMax
        }
      }
    case ACTION.GET_LUX_PER_AREA:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          meanLuxPerArea: rest.aggregateMeasurementsMean,
          maxLuxPerArea: rest.aggregateMeasurementsMax
        }
      }
    case ACTION.GET_CORRELATED_CO2:
      return {
        ...state,
        productivity: {
          ...state.productivity,
          correlatedCo2: rest.correlated
        }
      }
    // TRAFFIC
    case ACTION.GET_DAILY_TRAFFIC:
      return {
        ...state,
        dailyTraffic: {
          ...rest.dailyTraffic,
          occupants: rest.occupants,
          occupantsHour: rest.occupantsHour
        }
      }

    case ACTION.GET_REPORT:
      return {
        ...state,
        report: rest.report
      }

    // CUSTOM QUERIES
    // --------------
    case ACTION.GET_STANDARD_BANK_UTILIZATION:
      return {
        ...state,
        custom: {
          ...state.custom,
          stdbank: {
            ...state.custom?.stdbank,
            ...rest
          }
        }
      }

    case ACTION.GET_TECHDATA:
      return {
        ...state,
        custom: {
          ...state.custom,
          techdata: {
            ...state.custom?.techdata,
            ...rest
          }
        }
      }

    case ACTION.GET_BUTTON_EVENTS:
      return {
        ...state,
        events: rest.events
      }

    default:
      return state
  }
}
